import {
  epHttpClient,
  type GeocodeReverseData,
  formatFeatureToAddress,
} from '@ahm/api-wrappers-core';
import type { AxiosRequestConfig } from 'axios';
import { apiRoutes } from '../api-routes';
import { publicEnv } from '@/config/public-env.config.mjs';

export type GeocodeReverseParams = {
  lng: number;
  lat: number;
  profile?: string;
  'boundary.country'?: string;
  'boundary.circle.radius'?: string;
};

export const getGeocodeReverseFn = async (
  params: GeocodeReverseParams,
  config: AxiosRequestConfig = {}
) => {
  const response = await epHttpClient({
    apiURL: publicEnv.NEXT_PUBLIC_EP_API_URI,
    apiKey: publicEnv.NEXT_PUBLIC_EP_API_KEY,
  }).get<GeocodeReverseData>(apiRoutes.place.geocode_reverse, {
    params: {
      profile: 'ahamove',
      'boundary.country': 'VN',
      'boundary.circle.radius': '2000',
      ...params,
    },
    ...config,
  });
  return formatFeatureToAddress(response.data.data);
};
