import {
  COOKIE_NAME__PICKUP_LOCATION,
  LOCAL_STORAGE_KEY__FREQUENT_LOCATION,
  type Address,
  type AxiosError,
  type LngLat,
  type Path,
  type SavedPlace,
  type ShortestRoute,
  getDomain,
} from '@ahm/api-wrappers-core';
import { local } from '@ahm/common-helpers';
import { getCookie, hasCookie } from 'cookies-next/client';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createSelectors } from './selectors';
import { getGeocodeReverseFn } from '@/api/ahafood/get-revert-address-fn';

type LocationState = {
  location: LngLat | null;
  address: Address | null;
  error: Error | AxiosError | null;
  loading: boolean;
  paths: Path[];
  pathSortIndexes: number[] | null;
  selectedSavedPlace: SavedPlace | null;
  activePath: Path | null;
  currentLocation: Address | null;
  frequentLocations: Address[];
  pathFocusIndex: number | null;
  shortestRoute: ShortestRoute | null;
  shortestRouteIndex: number[];
  savedLocations: Address[];
};

type LocationActions = {
  detectLocation: (force?: boolean) => Promise<Address>;
  setLocation: (location: LngLat) => void;
  setAddress: (address: Address) => void;
  setPaths: (paths: Path[]) => void;
  setPathSortIndexes: (pathSortIndexes: number[] | null) => void;
  updatePathByIndex: (path: Path, index: number) => void;
  updateAllPath: (path: Path[]) => void;
  removePath: (index: number) => void;
  clearPaths: () => void;
  setActivePath: (path?: Path) => void;
  addToFrequentLocations: (location: Address) => void;
  setSelectedSavedPlace: (savedPlace: SavedPlace | null) => void;
  setPathFocusIndex: (pathIndex: number | null) => void;
  setShortestRoute: (shortestRoute: ShortestRoute | null) => void;
  setShortestRouteIndex: (shortestRouteIndex: number[]) => void;
  setSavedLocations: (saved: Address[]) => void;
};

const store = create<
  LocationState & LocationActions,
  [['zustand/devtools', LocationState & LocationActions]]
>(
  // eslint-disable-next-line sonarjs/cognitive-complexity
  devtools((set, get) => {
    const domain = getDomain();

    const pickupLocationCookie = hasCookie(COOKIE_NAME__PICKUP_LOCATION, {
      domain,
    })
      ? getCookie(COOKIE_NAME__PICKUP_LOCATION, {
          domain,
        })
      : undefined;

    const pickupPoint = pickupLocationCookie
      ? (JSON.parse(pickupLocationCookie.toString()) as Path)
      : undefined;

    let paths: Path[] = [];

    if (pickupPoint) {
      paths = [pickupPoint];
    }

    let frequentLocations: Address[] = [];

    try {
      const localFreqLocations = local.getItem(
        LOCAL_STORAGE_KEY__FREQUENT_LOCATION
      );

      if (localFreqLocations) {
        const parsedFreqLocations = JSON.parse(localFreqLocations);

        if (Array.isArray(parsedFreqLocations)) {
          frequentLocations = parsedFreqLocations;
        }
      }
    } catch (error) {
      console.error(
        'Error while parsing local storage frequent locations',
        error
      );
    }

    return {
      location: null,
      address: null,
      error: null,
      loading: false,
      paths,
      selectedSavedPlace: null,
      pathSortIndexes: null,
      frequentLocations,
      pathFocusIndex: null,
      shortestRoute: null,
      shortestRouteIndex: [],
      currentLocation: null,
      activePath: null,
      savedLocations: [],

      detectLocation: (force = false) => {
        return new Promise<Address>((resolve, reject) => {
          if (!(typeof navigator === 'object' && 'geolocation' in navigator)) {
            const error = new Error(
              'Phương thức hiện không được hỗ trợ, vui lòng thử lại sau.'
            );
            set({
              error,
              loading: false,
            });
            reject(error.message);
          } else {
            set({ loading: true, error: undefined });
            navigator.geolocation.getCurrentPosition(
              async (position: GeolocationPosition) => {
                const newLocation = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                } as LngLat;

                // if new location is the same as current location, do nothing
                const detectedLocation = get().location;
                const currentLocation = get().currentLocation;
                if (
                  !force &&
                  detectedLocation &&
                  currentLocation &&
                  detectedLocation.lat === newLocation.lat &&
                  detectedLocation.lng === newLocation.lng
                ) {
                  set({ loading: false });
                  resolve(currentLocation);
                  return;
                }

                try {
                  const address = await getGeocodeReverseFn(
                    apiConfig,
                    newLocation
                  );

                  if (!address) {
                    const error = new Error(
                      'Không thể xác định được vị trí hiện tại, vui lòng thử lại sau.'
                    );
                    set({
                      error,
                      loading: false,
                    });

                    reject(error.message);
                    return;
                  }

                  set((state) => ({
                    location: newLocation,
                    currentLocation: address,
                    paths:
                      state.paths.length === 0
                        ? [
                            {
                              ...address,
                              name: '',
                              mobile: '',
                            } as Path,
                          ]
                        : [...state.paths],
                    loading: false,
                  }));
                  resolve(address);
                } catch (error) {
                  set({
                    location: newLocation,
                    error: error as AxiosError,
                    loading: false,
                  });
                  reject((error as Error).message);
                }
              },
              (error: GeolocationPositionError) => {
                set({
                  error: error as unknown as Error,
                  loading: false,
                });
                reject(error.message);
              },
              {
                enableHighAccuracy: true,
                maximumAge: 60 * 60 * 1000,
                timeout: 8000,
              }
            );
          }
        });
      },
      setLocation: (location) => {
        if (!location?.lat || !location.lng) {
          set({ location: undefined, address: undefined });
          return;
        }
        set({ location });
      },
      setAddress: (address) => {
        if (!address?.lat || !address.lng) {
          set({ location: undefined, address: undefined });
          return;
        }
        const currentLocation = get().location;
        // Only update location if new lat, lng from address are different from current location
        if (
          currentLocation &&
          currentLocation.lat === address.lat &&
          currentLocation.lng === address.lng
        ) {
          set({ address });
          return;
        }
        set({
          location: { lat: address.lat, lng: address.lng } as LngLat,
          address,
        });
      },
      setPaths: (paths) => {
        set({ paths });
      },
      setPathSortIndexes: (pathSortIndexes) => {
        set({ pathSortIndexes });
      },
      updatePathByIndex: (path, index) => {
        set((state) => {
          const paths = [...state.paths];
          if (index === -1 || index === -2) {
            if (paths.length === 0) {
              delete path.cod;
              delete path.tracking_number;
            }
            paths.push(path);
          } else {
            if (index === 0) {
              delete path.cod;
              delete path.tracking_number;
            }
            paths[index] = path;
          }
          return { paths };
        });
      },
      updateAllPath: (paths) => {
        set({ paths });
      },
      removePath: (index) => {
        set((state) => ({
          paths: state.paths.filter((_, i) => i !== index),
        }));
      },
      clearPaths: () => {
        set({ paths: [] });
      },
      setActivePath: (path) => {
        set({ activePath: path });
      },
      setPathFocusIndex: (pathIndex) => {
        set({ pathFocusIndex: pathIndex });
      },
      addToFrequentLocations: (location: Address) => {
        const frequentLocations = get().frequentLocations;

        try {
          // Check if the location is already in the list based on lat and lng
          const existingIndex = frequentLocations.findIndex(
            (l) => l.lat === location.lat && l.lng === location.lng
          );

          if (existingIndex !== -1) {
            frequentLocations.splice(existingIndex, 1);
          }

          frequentLocations.unshift(location);

          if (frequentLocations.length > 3) {
            frequentLocations.pop();
          }

          set({ frequentLocations });
          local.setItem(
            LOCAL_STORAGE_KEY__FREQUENT_LOCATION,
            JSON.stringify(frequentLocations)
          );
        } catch (error) {
          console.error('Error adding frequent location', error);
        }
      },
      setSavedLocations(saved: Address[]) {
        set({ savedLocations: saved });
      },
      setSelectedSavedPlace: (savedPlace) => {
        set({ selectedSavedPlace: savedPlace });
      },
      setShortestRoute: (shortestRoute: ShortestRoute | null) => {
        set({ shortestRoute });
      },
      setShortestRouteIndex: (shortestRouteIndex: number[]) => {
        set({ shortestRouteIndex });
      },
    };
  })
);

export const useLocationStore = createSelectors(store);
