'use client';

import { JSFunction } from '@ahamove/js-function';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useIsMobile } from '@/lib/is-mobile';

export const DetectWebinappContext = createContext(false);

export function useDetectWebinappContext() {
  return useContext(DetectWebinappContext);
}

export const useDetectWebInApp = () => {
  const isMobile = useIsMobile();
  const contextWebinapp = useDetectWebinappContext();
  const [isWebinapp, setIsWebinapp] = useState(contextWebinapp);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const storedWebInApp = sessionStorage.getItem('isWebinapp') === 'true';
    const urlParams = new URLSearchParams(window.location.search);
    const hasWebInAppParam = urlParams.has('webinapp');

    if ((hasWebInAppParam || storedWebInApp) && isMobile) {
      if (!storedWebInApp) sessionStorage.setItem('isWebinapp', 'true');
      setIsWebinapp(true);

      JSFunction.call({ name: 'hide_toolbar' });
    }
  }, [isMobile]);

  return useMemo(() => isWebinapp, [isWebinapp]);
};
